import React from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import "./index.css";
import {
  SPECS_API_VERSION_DEFAULT,
  SPECS_API_VERSION_V1,
  SPECS_API_TYPE_MAIN,
  SPECS_API_TYPE_LEVEL_ONE,
  SPECS_API_TYPE_TERMINAL_SERVICE,
} from "./constants";
import App from "./App";

const Routes = () => {
  const { hash } = useLocation();

  return (
    <Switch>
      <Redirect
        exact
        from="/2020-08-01"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_DEFAULT}${hash}`}
      />
      <Redirect
        exact
        from="/2019-09-01"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_V1}${hash}`}
      />
      <Redirect
        exact
        from="/2019-09-01/level-one"
        to={`/${SPECS_API_TYPE_LEVEL_ONE}/${SPECS_API_VERSION_V1}${hash}`}
      />
      <Redirect
        exact
        from="/level-one-api/v2019"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_DEFAULT}${hash}`}
      />
      <Redirect
        exact
        from="/main-api/v2020"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_DEFAULT}${hash}`}
      />
      <Redirect
        exact
        from="/main-api/v2019"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_V1}${hash}`}
      />
      <Redirect
        exact
        from="/terminal-service"
        to={`/${SPECS_API_TYPE_TERMINAL_SERVICE}${hash}`}
      />
      <Route exact path="/:apiType/:apiVersion" component={App} />
      <Route exact path="/:apiType" component={App} />
      <Redirect
        from="/"
        to={`/${SPECS_API_TYPE_MAIN}/${SPECS_API_VERSION_DEFAULT}`}
      />
    </Switch>
  );
};

export default Routes;
