import React, { useState, useEffect, useRef } from "react";
import { Link, Redirect } from "react-router-dom";
import { RedocStandalone } from "@seamlesspay/redoc";
import classNames from "classnames";
import { Menu } from "@headlessui/react";
import { useWindowSize, useEventListener } from "usehooks-ts";

import {
  SPECS_API_TYPES,
  SPECS_API_TYPES_URLS_PATHS_MAP,
  SPECS_API_TYPES_NAMES_MAP,
  SPECS_API_TYPES_VERSION_MAP,
  SPECS_API_TYPE_MAIN,
  SPECS_API_VERSION_HIDDEN,
  SPECS_API_VERSION_DROPDOWN_VISIBLE
} from "./constants";
import { getApiVersion, getSpecsPath, getSpecsType } from "./utils";
import Dropdown from "./components/Dropdown";
import theme from "./theme";
import "./App.css";

const SHADOW_DESKTOP =
  "288px 2px 20px rgba(0, 0, 0, 0.1), 288px 1px 16px rgba(0, 0, 0, 0.06)";
const SHADOW_MOBILE =
  "0px 2px 20px rgba(0, 0, 0, 0.1), 0px 1px 16px rgba(0, 0, 0, 0.06)";

const App = (props) => {
  const {
    match: {
      params: {
        apiType = SPECS_API_TYPE_MAIN,
        apiVersion = SPECS_API_VERSION_HIDDEN,
      },
    },
  } = props;

  const { width } = useWindowSize();
  const [loading, setLoading] = useState(true);

  const navRef = useRef(null);

  const currentSpecType = getSpecsType(apiType);
  const currentApiVersion = getApiVersion(apiType, apiVersion);

  const onScroll = () => {
    if (window.scrollY > 0) {
      navRef.current.style.boxShadow =
        width > 810 ? SHADOW_DESKTOP : SHADOW_MOBILE;
    } else {
      navRef.current.style.boxShadow = "";
    }
  };

  useEventListener("scroll", onScroll);

  useEffect(() => {
    setLoading(true);
    console.log(apiType, apiVersion);
  }, [apiType, apiVersion]);

  if (!currentSpecType) return <Redirect to={`/${SPECS_API_TYPE_MAIN}`} />;

  if (
    apiVersion !== SPECS_API_VERSION_HIDDEN &&
    apiVersion !== currentApiVersion
  ) {
    return <Redirect to={`${currentSpecType}/${currentApiVersion}`} />;
  }

  return (
    <div className={classNames("App relative", !loading && "pt-32 sm:pt-20")}>
      <nav
        className={classNames(
          "z-50 top-0 w-full h-[128px] sm:h-[80px] flex space-y-4 sm:space-y-0 justify-center sm:justify-between flex-col sm:flex-row items-start sm:items-center border-b border:slate-200 bg-white px-4 sm:px-[30px]",
          !loading && "fixed"
        )}
        ref={navRef}
      >
        <a href="/">
          <img
            src="/images/logo.svg"
            width="223"
            height="40"
            alt="Seamless Payments Documentation"
            className="w-[158px] h-[28px] sm:w-[223px] sm:h-[40px]"
          />
        </a>
        <div className="space-x-2 flex justify-between sm:justify-normal sm:min-w-[220px] sm:max-w-[300px] w-full sm:w-auto">
          <Dropdown
            current={SPECS_API_TYPES_NAMES_MAP[currentSpecType]}
            className="w-full min-w-[119px]"
          >
            {SPECS_API_TYPES.map((specType, index) => (
              <Menu.Item key={specType}>
                {({ active }) => (
                  <Link
                    className={classNames(
                      "block px-3 py-2 text-slate-900 font-normal text-sm leading-4",
                      (specType === currentSpecType || active) && "bg-slate-200"
                    )}
                    to={`/${SPECS_API_TYPES_URLS_PATHS_MAP[specType]}`}
                  >
                    {SPECS_API_TYPES_NAMES_MAP[specType]}
                    {index === 0 && <span className="sr-only">(current)</span>}
                  </Link>
                )}
              </Menu.Item>
            ))}
          </Dropdown>
          {
            (
              !SPECS_API_TYPES_VERSION_MAP[currentSpecType].includes(SPECS_API_VERSION_HIDDEN) ||
              SPECS_API_TYPES_VERSION_MAP[currentSpecType].includes(SPECS_API_VERSION_DROPDOWN_VISIBLE)
            ) &&
            (
              <Dropdown
                current={currentApiVersion}
                className="w-full sm:w-auto"
              >
                {SPECS_API_TYPES_VERSION_MAP[currentSpecType]
                  .filter(
                    (val) =>
                      ![
                        SPECS_API_VERSION_DROPDOWN_VISIBLE,
                        SPECS_API_VERSION_HIDDEN,
                      ].includes(val)
                  )
                  .map((version, index) => {
                    console.log(version);
                    return (
                      <Menu.Item key={[version, index].join("-")}>
                        {({ active }) => (
                          <Link
                            className={classNames(
                              "block px-3 py-2 text-slate-900 font-normal text-sm leading-4",
                              (version === currentApiVersion || active) &&
                                "bg-slate-200"
                            )}
                            to={`/${SPECS_API_TYPES_URLS_PATHS_MAP[currentSpecType]}/${version}`}
                          >
                            {version}
                          </Link>
                        )}
                      </Menu.Item>
                    );
                  })}
              </Dropdown>
            )}
        </div>
      </nav>
      <RedocStandalone
        key={currentSpecType}
        options={{
          theme,
          sortPropsAlphabetically: true,
          hideLoading: true,
          scrollYOffset: width > 640 ? 80 : 128,
        }}
        onLoaded={() => setLoading(false)}
        {...{
          specUrl: getSpecsPath({
            type: currentSpecType,
            version: currentApiVersion,
          }),
        }}
      />
      {loading && (
        <div className="w-screen min-h-full flex justify-center items-center loader overflow-y-scroll fixed">
          <div className="relative flex flex-col gap-y-4 text-xl leading-6 font-semibold justify-center items-center">
            <div className="relative spinner animate-spin mb-8" />
            Loading...
          </div>
        </div>
      )}
    </div>
  );
};

export default App;
