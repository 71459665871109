export default {
  spacing: {
    unit: 4,
    sectionHorizontal: 32,
    sectionVertical: 24,
  },
  typography: {
    fontSize: "16px",
    lineHeight: "1.5em",
    fontWeightRegular: "400",
    fontWeightBold: "600",
    fontWeightLight: "300",
    fontFamily: "'Titillium Web', sans-serif",
    links: {
      color: "#2563EB",
      visited: "#475569",
    },
  },
  sidebar: {
    width: "288px",
    backgroundColor: "#F1F5F9",
    textColor: "#0F172A",
    groupItems: {
      activeBackgroundColor: "#CBD5E1",
      activeTextColor: "#2563EB",
    },
    level1Items: {
      activeBackgroundColor: "rgba(203, 213, 225, 0.7)",
      activeTextColor: "#2563EB",
    },
    arrow: {
      size: "1.5em",
      color: "#64748B",
    },
  },
  logo: {
    maxHeight: 0,
    maxWidth: 0,
  },
  rightPanel: {
    backgroundColor: "#1E293B",
    textColor: "#FFFFFF",
  },
};
