import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";

import ErrorBoundary from "./components/ErrorBoundary";
import Routes from "./Routes";
import "./index.css";

const RouteApp = () => {
  return (
    <ErrorBoundary fallback={<p>Something went wrong</p>}>
      <BrowserRouter>
        {/* Separate routes so we can use useLocation hook to redirect url params */}
        <Routes />
      </BrowserRouter>
    </ErrorBoundary>
  );
};

ReactDOM.render(<RouteApp />, document.getElementById("root"));
