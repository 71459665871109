export function objectHas(object: object, path: string | Array<string>): boolean {
  let _path = <Array<string>>path;

  if (typeof path === 'string') {
    _path = path.split('.');
  }

  return _path.every((key: string) => {
    if (typeof object != 'object' || object === null || !(key in object)) return false;
    object = object[key];
    return true;
  });
}

export function objectSet(object: object, path: string | Array<string>, value: any): void {
  let _path = <Array<string>>path;

  if (typeof path === 'string') {
    _path = path.split('.');
  }
  const limit = _path.length - 1;
  for (let i = 0; i < limit; ++i) {
    const key = _path[i];
    object = object[key] ?? (object[key] = {});
  }
  const key = _path[limit];
  object[key] = value;
}

export function sortObject(object) {
  //Thanks > http://whitfin.io/sorting-object-recursively-node-jsjavascript/
  if (!object) {
    return object;
  }

  const isArray = object instanceof Array;
  let sortedObj = {};
  if (isArray) {
    sortedObj = object.map((item) => sortObject(item));
  } else {
    if (typeof object === 'string' || object instanceof String)
      return object

    const keys = Object.keys(object);
    // console.log(keys);
    keys.sort(function(key1, key2) {
      (key1 = key1.toLowerCase()), (key2 = key2.toLowerCase());
      if (key1 < key2) return -1;
      if (key1 > key2) return 1;
      return 0;
    });

    for (const index in keys) {
      const key = keys[index];
      if (typeof object[key] == 'object') {
        sortedObj[key] = sortObject(object[key]);
      } else {
        sortedObj[key] = object[key];
      }
    }
  }

  return sortedObj;
}
