import {
  HTTP_LOCAL_HOST,
  HTTP_LOCAL_PORT,
  ENV_PROD,
  SPECS_API_TYPES_URLS_PATHS_MAP,
  SPECS_API_TYPES_VERSION_MAP,
  SPECS_API_VERSION_DEFAULT,
  SPECS_API_VERSION_HIDDEN,
} from "./../constants";

const { NODE_ENV } = process.env;

export const swapKeyValue = (target) =>
  Object.keys(target).reduce(
    (accum, key) => ({ ...accum, [target[key]]: key }),
    {}
  );

export const getSpecsPath = ({ type, version }) =>
  NODE_ENV !== ENV_PROD
    ? `${HTTP_LOCAL_HOST}:${HTTP_LOCAL_PORT}/seamlesspay-${type}-${version}.yaml`
    : `https://api-specs.${process.env.REACT_APP_ROOT_DOMAIN}/seamlesspay-${type}-${version}.yaml`;

export const getSpecsType = (apiType = "") => {
  const typesByUrlsMap = swapKeyValue(SPECS_API_TYPES_URLS_PATHS_MAP);

  return typesByUrlsMap[apiType];
};

export const getApiVersion = (apiType = "", apiVersion = "") => {
  const specApis = SPECS_API_TYPES_VERSION_MAP[apiType];

  if (!specApis) return undefined;

  if (specApis.includes(SPECS_API_VERSION_HIDDEN))
    return SPECS_API_TYPES_VERSION_MAP[apiType][0];

  if (specApis.includes(apiVersion)) return apiVersion;
  if (specApis.includes(SPECS_API_VERSION_DEFAULT))
    return SPECS_API_VERSION_DEFAULT;
  return SPECS_API_TYPES_VERSION_MAP[apiType][0];
};

export default {
  getSpecsPath,
  getSpecsType,
};
